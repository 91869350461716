import React, { useState } from "react";
import tw from "tailwind.macro";
import styled from '@emotion/styled';
import { Link } from "gatsby"
import { LogoMarkBlack } from "./LogoMark";

function Nav() {

  const [showNavOpt, setShowNavOpt] = useState(false);

  const Nav = tw.nav`
    w-full py-6 
    sm:px-6 md:px-4
  `;
  const NavContent = tw.nav`
    flex items-center justify-between flex-wrap bg-white max-w-6xl m-auto
    sm:flex-col
  `;

  const NavContainerTitle = tw.div`
    sm:flex sm:justify-between sm:w-full items-center 
  `;

  const Logo = tw.div`
    flex items-center flex-shrink-0 mr-6
  `;

  const NavContainerOptsMobile = styled('span')`
    ${tw`hidden flex-col w-full flex-grow items-center w-auto
    sm:flex sm:justify-between sm:py-12 sm:w-full sm:absolute sm:bg-white`};
    top: 4.875rem;
    z-index: 2;
    height: calc(100vh - 70px);
    min-height: 31.25rem;
  `;

  const NavContainer = tw.span`
    w-full block flex-grow flex items-center w-auto 
    sm:hidden
  `;

  const NavList = tw.div`
    text-sm flex-grow sm:flex sm:flex-col
  `;

  const NavItem = tw.div`
    block mt-4 inline-block mt-0 text-themeGreen-800 hover:text-themeGray-300 mr-4 font-bold self-center
    sm:mr-0 sm:my-6
  `;

  const NavItemSm = tw.div`
    sm:block hidden mt-4 mt-0 text-themeGreen-800 hover:text-themeGray-300 font-bold self-center
    sm:py-0 sm:my-6
  `;

  const NavContainerMobile = tw.div`
    block hidden
    sm:block
  `;

  const NavContainerExpandButton = tw.button`
    flex items-center px-3 py-2 text-black hover:text-green-900
  `;

  const NavContainerExpandHamburger = tw.svg`
    fill-current h-5 w-5 
  `;

  const NavContainerCloseSvg = tw.svg`
    fill-current h-5 w-5 
  `;

  const CTAButton = tw.button`
    bg-themeGreen-500 border border-themeGreen-500 text-xs px-5 py-3 leading-none text-white 
    sm:max-w-md sm:w-full sm:py-5
  `;

  const SuppliesButtonContainer = tw.div`
    sm:mt-0 sm:w-full text-center sm:self-end sm:mb-20
  `;

  const SquaresTwoColumns = styled("svg")`
    ${tw`fill-current hidden sm:block absolute`};
    left: 0.625rem;
    bottom: 14rem;
  `;

  const SquaresTriangle = styled("svg")`
    ${tw`fill-current hidden sm:block absolute`};
    right: 0;
    bottom: 12rem;
  `;

  const SquareColumnSvg = () => {
    return <SquaresTwoColumns width="35" height="339" viewBox="0 0 35 339" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 0)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 27.645)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 27.645)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 55.2905)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 55.2905)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 82.9355)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 82.9355)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 110.581)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 110.581)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 138.226)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 138.226)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 165.871)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 165.871)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 193.516)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 193.516)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 221.162)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 221.162)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 248.807)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 248.807)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 276.452)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 276.452)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 304.097)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 304.097)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 331.742)" fill="#FBB89C" />
      <rect width="6.91129" height="6.91129" transform="matrix(-4.37114e-08 1 1 4.37114e-08 27.6445 331.742)" fill="#FBB89C" />
    </SquaresTwoColumns>
  }

  const SquareTriangleSvg = () => {
    return <SquaresTriangle width="136" height="192" viewBox="0 0 136 192" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M31.211 66.3564L56.8164 40.751L82.4218 66.3564H31.211Z" fill="#FBB89C" />
        <rect width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="15.6733" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="31.3472" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="47.0205" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="62.6938" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="109.714" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="125.388" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="141.061" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="156.735" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="172.408" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect y="188.082" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="15.6733" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="31.3472" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="47.0205" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="62.6938" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="109.714" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="125.388" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="141.061" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="156.735" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="172.408" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="15.6738" y="188.082" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="15.6733" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="31.3472" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="47.0205" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="109.714" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="125.388" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="141.061" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="156.735" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="172.408" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="31.3477" y="188.082" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="15.6733" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="31.3472" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="109.714" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="125.388" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="141.061" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="156.735" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="172.408" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="47.0195" y="188.082" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="15.6733" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="31.3472" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="109.714" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="125.388" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="141.061" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="156.735" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="172.408" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="62.6934" y="188.082" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="15.6733" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="31.3472" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="47.0205" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="109.714" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="125.388" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="141.061" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="156.735" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="172.408" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="78.3672" y="188.082" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="15.6733" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="31.3472" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="47.0205" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="62.6938" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="109.714" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="125.388" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="141.061" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="156.735" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="172.408" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="94.041" y="188.082" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="15.6733" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="31.3472" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="47.0205" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="62.6938" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="109.714" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="125.388" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="141.061" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="156.735" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="172.408" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="109.715" y="188.082" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="15.6733" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="31.3472" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="47.0205" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="62.6938" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="78.3672" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="94.041" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="109.714" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="125.388" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="141.061" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="156.735" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="172.408" width="3.91837" height="3.91837" fill="#FBB89C" />
        <rect x="125.389" y="188.082" width="3.91837" height="3.91837" fill="#FBB89C" />
      </g>
    </SquaresTriangle>
  }

  const handleClick = () => {
    setShowNavOpt(!showNavOpt)
  }

  const navOptions = () => {
    return <>

      <NavList>
        <NavItemSm>
          <Link to="/">
            Home
            </Link>
        </NavItemSm>

        <NavItem>
          <a href="https://secure.squarespace.com/checkout/donate?donatePageId=5e7b676d53c38d47a7c9ed70&ss_cid=a8e2e880-e7af-4fe4-8a2b-9670ddcfb885&ss_cvisit=1589591662979&ss_cvr=8c372247-4a02-4646-a8a0-0010701b9c9f%7C1587414536379%7C1589306236254%7C1589591662531%7C20" target="_blank">
            Donate
          </a>
        </NavItem>

        <NavItem>
          <Link to="/team/">
            Team
          </Link>
        </NavItem>

        <NavItem>
          <Link to="/volunteer/">
            Volunteer
          </Link>
        </NavItem>
      </NavList>

      <SuppliesButtonContainer>
        <Link to="/supply/">
          <CTAButton href="#">
            Request Supplies
          </CTAButton>
        </Link>
      </SuppliesButtonContainer>
    </>
  }

  return (
    <Nav>
      <NavContent>
        <NavContainerTitle>
          <Link to="/">
            <Logo> <LogoMarkBlack /> </Logo>
          </Link>
          <NavContainerMobile onClick={handleClick}>
            <NavContainerExpandButton>

              {showNavOpt ? (
                <NavContainerCloseSvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="7.41406" y="6" width="16" height="2" transform="rotate(45 7.41406 6)" fill="#062E37" />
                  <rect x="6" y="17.3135" width="16" height="2" transform="rotate(-45 6 17.3135)" fill="#062E37" />
                </NavContainerCloseSvg>
              ) : (
                  <NavContainerExpandHamburger viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </NavContainerExpandHamburger>
                )}
            </NavContainerExpandButton>
          </NavContainerMobile>
        </NavContainerTitle>

        {showNavOpt ?
          <NavContainerOptsMobile>
            {SquareColumnSvg()}
            {SquareTriangleSvg()}
            {navOptions()}
          </NavContainerOptsMobile>
          :
          <></>
        }

        <NavContainer>
          {navOptions()}
        </NavContainer>
      </NavContent>
    </Nav>
  )
}

export default Nav
