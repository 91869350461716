import React from "react";
import tw from "tailwind.macro";
import styled from '@emotion/styled';
import { LogoMarkWhite } from "../components/LogoMark";
import { Link } from "gatsby"
import { css } from '@emotion/core';

const Logo = tw.div`
  flex items-center flex-shrink-0 mr-6 
  sm:justify-center sm:my-8 sm:mr-0
`;

// Footer Section
// =====================================
const FooterSection = tw.div`
  flex flex-col w-full bg-themeGreen-700 font-light items-center
`;


const FooterSectionDivider = styled('div')`
  ${tw`flex flex-row w-full border-b-2 border-white`}
  opacity: 0.1;
`;

const FooterSectionRow = tw.div`
  max-w-6xl flex flex-row w-full justify-between text-white pt-16 pb-6 
  md:px-4 sm:flex-col sm:py-8 sm:px-8
`;

const FooterSectionRowItem = styled('div')`
  ${ tw`sm:text-center`};

  ${(props) => !!props.center ? tw`text-center` : tw`text-left`};

  font-size: 0.625rem;
  line-height: 185%;
  min-width: 14.375rem;

  p, div {
    max-width: 38.125rem;
    label {
      opacity: 0.45;
    }
  }
`;

const SocialSvgAnchor = styled('a')`
`;

const SocialSVG = styled('svg')`
   ${tw`mx-4 sm:mx-4 sm:mt-4 sm:mb-2`};
  display: inline-block;
`;

const StyledLink = styled('a')`
  opacity: 1;
  ${ tw`text-themeOrange-500 sm:block sm:mb-2`};
`;

const StyledBr = styled('br')`
  
`;

export default () => (
  <FooterSection>
    <FooterSectionRow>
      <FooterSectionRowItem>
        <Logo> <LogoMarkWhite /> </Logo>
      </FooterSectionRowItem>
      <FooterSectionRowItem center>
        <p>
          <label>
            © 2020 Operation Masks. All rights reserved.
          </label>
          <StyledBr />
          <label>
            Operation Masks is an registered 501(c)(3) non-profit organization with EIN 84-5188687.
          </label>
          <StyledBr />
        </p>
      </FooterSectionRowItem>
      <FooterSectionRowItem css={tw`flex md:justify-end sm:justify-center`}>
        <SocialSvgAnchor href={'https://twitter.com/operationmasks'}>
          <SocialSVG width="24" height="24" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M20.5 2.90121C19.7563 3.24252 18.9638 3.46875 18.1375 3.57859C18.9875 3.04767 19.6363 2.21336 19.9413 1.20775C19.1488 1.70206 18.2737 2.05121 17.3412 2.24606C16.5887 1.40783 15.5162 0.888672 14.3462 0.888672C12.0762 0.888672 10.2487 2.81621 10.2487 5.17921C10.2487 5.51921 10.2762 5.84613 10.3438 6.15736C6.935 5.98344 3.91875 4.27429 1.8925 1.67067C1.53875 2.31275 1.33125 3.04767 1.33125 3.83883C1.33125 5.32436 2.0625 6.64121 3.1525 7.40359C2.49375 7.39052 1.8475 7.19044 1.3 6.87529C1.3 6.88836 1.3 6.90536 1.3 6.92236C1.3 9.00683 2.72125 10.7382 4.585 11.1371C4.25125 11.2325 3.8875 11.2783 3.51 11.2783C3.2475 11.2783 2.9825 11.2626 2.73375 11.2051C3.265 12.9037 4.7725 14.1526 6.565 14.1931C5.17 15.3347 3.39875 16.0226 1.48125 16.0226C1.145 16.0226 0.8225 16.0069 0.5 15.9637C2.31625 17.1891 4.46875 17.8887 6.79 17.8887C14.335 17.8887 18.46 11.3502 18.46 5.68267C18.46 5.49306 18.4538 5.30998 18.445 5.12821C19.2588 4.52406 19.9425 3.76952 20.5 2.90121Z" fill="white" />
          </SocialSVG>
        </SocialSvgAnchor>

        <SocialSvgAnchor href={'https://www.instagram.com/operationmasks/'}>
          <SocialSVG width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" fill="white" />
          </SocialSVG>
        </SocialSvgAnchor>

      </FooterSectionRowItem>
    </FooterSectionRow>

    <FooterSectionDivider />
    <FooterSectionRow css={tw`pt-6 pb-24`}>
      <FooterSectionRowItem>
        <div css={tw`mb-3`}>
          <label>
            If you are a Supplier, please fill our
          </label>
          <StyledLink href="https://airtable.com/shraqF6m6rxkKTkeh"> supplier intake form</StyledLink>
        </div>
        <div>
          <label>
            For all other inquiries please reach out at
          </label>
          <StyledLink href="mailto:contact@operationmasks.org"> contact@operationmasks.org</StyledLink>
        </div>
      </FooterSectionRowItem>
      <FooterSectionRowItem>
        <div css={tw`mb-3`}>
          <label>
            If you want to volunteer with us, please fill our
          </label>
          <StyledLink href="/volunteer/"> volunteer intake form</StyledLink>
        </div>
        <div>
          <label>
            For media inquiries message
          </label>
          <StyledLink href="mailto:media@operationmasks.org"> media@operationmasks.org</StyledLink>
        </div>
      </FooterSectionRowItem>

    </FooterSectionRow>
  </FooterSection >
);
