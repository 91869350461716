import React from "react";
import tw from "tailwind.macro";
import styled from '@emotion/styled';
import rectangles from '../images/rectangles.svg';
import triangleTopleft from '../images/triangle-topleft.svg';
import triangleCenterRight from '../images/triangle-centerright.svg';
import rectanglesThin from '../images/rectangles-thin.svg';


// Contact Section
// =====================================
const ContactSectionContainer = tw.div`
  flex flex-col w-full bg-themeGreen-200 sm:pt-8 relative
`;

const ContactSection = tw.div`
  flex flex-col font-medium items-center py-24 lg:mx-20 relative
  sm:px-6 sm:pt-4 sm:pb-6
`;

const ContactSectionText = tw.h3`
  text-3xl text-themeGreen-600 pb-16 relative
  sm:pt-0
`;

const ContactSectionGrid = styled("form")`
  ${tw`w-1/2 sm:w-full sm:flex sm:flex-col`};
  @media (min-width: 801px) {
    /* large screens and up */
    display: grid;
    row-gap: 2rem;
    column-gap: 2rem;
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
`;

const ContactSectionInput = styled("input")`
  ${tw`border-themeGreen-500 bg-transparent border p-3 text-sm sm:mb-5`};

  @media (min-width: 801px) {
    /* large screens and up */
    grid-column: span 1/span 1 !important;
  }

  ::placeholder {
    color: #23A091 !important;
  }
`;

const ContactSectionButton = styled("button")`
  ${tw`bg-themeGreen-800 text-white p-3 text-sm`};
  grid-column: span 1/span 1 !important;
`;

const SquaresTwoColumns = styled("svg")`
 ${tw`fill-current hidden sm:block`};
 ${(props) => !!props.row ? tw`ml-6 mb-2 ` : tw`rotate-270 absolute`};
 top: ${props =>
    props.row ? 'unset' : '8%'};
 right: ${props =>
    props.row ? 'unset' : '-5.5%'};
`;

const TriangleSvg = tw.svg` 
  fill-current hidden sm:block rotate-45 ml-8
`;

const GraphTitle = styled("div")` 
  ${tw`sm:hidden`};
  position: absolute;
  left: 0;
`;

const GraphBottom = styled("div")` 
  ${tw`sm:hidden`};
  position: absolute;
  left: 0;
  bottom: 5px;
`;

const GraphBottomTriangle = styled("div")` 
  ${tw`sm:hidden`};
  position: absolute;
  right: 16%;
  bottom: 5px;
`;

const GraphRight = styled("div")` 
  ${tw`sm:hidden`};
  position: absolute;
  right: 1%;
  top: 18%;
  max-width: 213px;
`;

const svgColor = "#FBB89C"

const SquareSvgContent = () => {
  return <>
    <rect y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="37.3066" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="37.3066" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="74.6123" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="74.6123" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="111.918" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="111.918" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="149.225" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="149.225" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="186.53" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="186.53" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="223.837" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="223.837" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="261.143" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="261.143" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="298.449" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="298.449" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="335.755" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="335.755" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="373.062" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="373.062" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="410.367" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="410.367" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="447.674" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="447.674" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
  </>
}

const SquareColumnSvgContent = () => {
  return <>
    <rect y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect y="-35" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="37.3066" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="37.3066" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="37.3066" y="-35" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="74.6123" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="74.6123" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="74.6123" y="-35" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="111.918" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="111.918" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="111.918" y="-35" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="149.225" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="149.225" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="149.225" y="-35" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="186.53" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="186.53" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="186.53" y="-35" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="223.837" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="223.837" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="223.837" y="-35" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="261.143" y="0.530273" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="261.143" y="37.8359" width="9.32653" height="9.32653" fill={svgColor} />
    <rect x="261.143" y="-35" width="9.32653" height="9.32653" fill={svgColor} />
  </>
}

const SquaresRowSvgFooter = () => {
  return <SquaresTwoColumns row width="257" height="48" viewBox="0 0 457 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <SquareSvgContent />
  </SquaresTwoColumns>;
}

const SquaresColumnSvgFooter = () => {
  return <SquaresTwoColumns width="157" height="68" viewBox="0 0 257 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <SquareColumnSvgContent />
  </SquaresTwoColumns>;
}

const Triangle = () => {
  return <TriangleSvg width="37" height="67" viewBox="0 0 37 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.1045 66.1736L0.563477 33.5L36.1045 0.826398V66.1736Z" fill={svgColor} />
  </TriangleSvg>;
}

export default () => (
  <ContactSectionContainer>
    <GraphRight>
      <img src={rectangles} />
    </GraphRight>
    <Triangle />
    <SquaresColumnSvgFooter />
    <ContactSection>
      <GraphTitle>
        <img src={triangleTopleft} />
      </GraphTitle>
      <GraphBottomTriangle>
        <img src={triangleCenterRight} />
      </GraphBottomTriangle>
      <GraphBottom>
        <img src={rectanglesThin} />
      </GraphBottom>
      <ContactSectionText>
        Keep in touch
        </ContactSectionText>
      <ContactSectionGrid data-netlify="true" data-netlify-honeypot="bot-field" id="contactForm" name="contact" method="POST" action="/form-success">
        {/*
          This hidden field is necessary in order for netlify to work to capture form submissions.
          See: https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#form-handling-with-static-site-generators
        */}
        <input type="hidden" name="form-name" value="contact" />
        <ContactSectionInput name="first-name" placeholder="First name"></ContactSectionInput>
        <ContactSectionInput name="last-name" placeholder="Last name"></ContactSectionInput>
        <ContactSectionInput name="email" placeholder="Email"></ContactSectionInput>
        <ContactSectionButton type="submit" form="contactForm">Sign up</ContactSectionButton>
      </ContactSectionGrid>
    </ContactSection >
    <SquaresRowSvgFooter />
  </ContactSectionContainer>
)
